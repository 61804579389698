<template>
  <div>
    <h2>{{ loadingMsg }}</h2>
    <v-form v-if="!loadingMsg" ref="formCreateCollectRule" @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="4">
          <v-switch
            label="Todas cidades"
            v-model="form.all_cities"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="4">
          <v-switch
            label="Desconto ICMS?"
            v-model="form.icms"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="4">
          <v-switch
            label="Desconto Pedágio?"
            v-model="form.toll_discount"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            label="Parceira"
            v-model="form.partner_id"
            :items="partners"
            item-text="name"
            item-value="id"
            :loading="partners.length < 1"
            :rules="[v => !! v || 'Este campo é obrigatório']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Porcentagem"
            v-model="form.percentage"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Frete Mínimo"
            v-model="form.min_freight"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-if="!form.all_cities"
            label="Cidade"
            v-model="form.city_id"
            :items="cities"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
      </v-row>
      <v-btn color="success" type="submit" :loading="loading">
        Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
  export default {
		props: {
      id: {
        type: Number,
        default: null,
      },
			loading: {
				type: Boolean,
				default: false,
			},
			form: {
				type: Object,
				default() {
					return {
						partner_id: null,
						city_id: null,
						all_cities: false,
						icms: false,
						percentage: null,
						min_freight: null,
						toll_discount: 0,
					}
				}
			},
		},
    data() {
      return {
        loadingMsg: null,
        partners: [],
        cities: [],
      }
    },
    mounted () {
      this.getPartners();
    },
    methods: {
      getRule() {
        if(this.id) {
          this.loadingMsg = 'Carregando regra';
          this.$http.get(`api/collect-rules/${this.id}`)
            .then(res => {
              this.form.partner_id = parseInt(res.data.partner_id);
              this.form.city_id = parseInt(res.data.city_id);
              this.form.all_cities = res.data.all_cities;
              this.form.icms = res.data.icms;
              this.form.percentage = res.data.percentage;
              this.form.min_freight = res.data.min_freight;
              this.form.toll_discount = res.data.toll_discount;
              this.loadingMsg = null;
            }).catch(err => {
              this.loadingMsg = 'Erro ao carregar';
              console.log(err);
            });
        }
      },
			cleanForm() {
				this.$refs.formCreateCollectRule.reset()
			},
      save() {
        if(this.$refs.formCreateCollectRule.validate()) {
          this.$emit('submited', this.form);
        }
      },
      getPartners() {
        this.loadingMsg = 'Carregando parceiras';
        this.$http.get('api/partners')
        .then(res => {
          this.partners = res.data;
          this.getCities();
        }).catch(err => {
          console.log(err);
          this.$side({
            type    : 'error',
            msg     : 'Erro ao carregar parceiras',
            duration: 4000
          });
        });
      },
      getCities() {
        this.loadingMsg = 'Carregando cidades';
        this.$http.get('api/cities')
        .then(res => {
          this.cities = res.data;
          if(!this.id) {
            this.loadingMsg = null;
          }
          this.getRule();
        }).catch(err => {
          console.log(err);
          this.$side({
            type    : 'error',
            msg     : 'Erro ao carregar cidades',
            duration: 4000
          });
        });
      }
    },
  }
</script>