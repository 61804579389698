<template>
	<div>
		<h1>Nova regra</h1>

		<v-btn
      color="error"
      @click="$router.push({name: 'collectRules.index'})"
      class="ma-2"
      title="Voltar"
      fab
      small
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

		<Form ref="collectRuleFormComponent" :id="$route.params.id ? $route.params.id : null" :loading="loading" @submited="handleSubmit" />
	</div>
</template>

<script>
	import Form from '../components/Form.vue'

	export default {
		components: {
			Form
		},
		data() {
			return {
				loading: false,
			}
		},
		methods: {
			async handleSubmit(data) {
				this.loading = true;

				if(!this.$route.params.id) {
					const rule = await this.$http.post(`api/collect-rules`, data)
					.catch((err) => {
						console.log(err);
						this.$toast.error('Erro ao salvar');
						return;
					});

					if(rule) { 
						this.$emit('created', rule.data);
						this.$refs.collectRuleFormComponent.cleanForm();
					}
				} else {
					try {
						await this.$http.put(`api/collect-rules/${this.$route.params.id}`, data)
						this.$toast.success("Salvo com sucesso");
					} catch (error) {
						this.$toast.error('Erro ao salvar');
					}
				}

				this.loading = false;
			}
		},
	}
</script>